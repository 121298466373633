import {memo, useCallback, useState} from 'react';
import {Toast, ToastBody, ToastHeader} from 'reactstrap';
import {CloseIcon} from '../../assets/icons/close';
import {Wrapper} from './toast.styles';

export const ToastComponent = memo(({title, body}) => {
  const [hide, activateHide] = useState(false);
  const hideNotification = useCallback(() => {
    activateHide(true);
  }, []);
  return (
      <Wrapper
          hide={hide}
          initial={{opacity: 0, y: 50, scale: 0.3}}
          animate={{opacity: 1, y: 0, scale: 1}}
          exit={{opacity: 0, scale: 0.5, transition: {duration: 0.2}}}>
        <Toast>
          <ToastHeader>
            <div className="d-flex justify-content-between align-items-center">
              <span>{title} </span>
              <span onClick={hideNotification}>
              <CloseIcon style={{width: '20px', height: '20px'}} />
            </span>
            </div>
          </ToastHeader>
          <ToastBody>{body}</ToastBody>
        </Toast>
      </Wrapper>
  );
});